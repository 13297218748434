import React, { ReactNode } from 'react';
import classnames from 'classnames';

import styles from './TryItNowCTA.module.scss';
import Badge from './Badge';
import CallToAction from './CallToAction';
import Grid from './layout/Grid';
import Image from './Image';

import bigGooseParty from '../media/geese/big-goose-party.png';
import bigGooseParty2x from '../media/geese/big-goose-party2x.png';
import bigGooseParty3x from '../media/geese/big-goose-party3x.png';

type Props = {
  title?: ReactNode;
  desc?: ReactNode;
  cardDesc?: ReactNode;
  withBadges?: boolean;
  className?: string;
};

const defaultProps: Partial<Props> = {
  title: <>Try it now for free</>,
  desc: (
    <>
      What&#39;s better than a demo? Access to the full platform! Build and run an Experience today. Create as many
      Missions as you can muster, invite up to 3 teams, and watch the magic happen.
    </>
  ),
};

const TryItNowCTA = ({ title, desc, withBadges, className }: Props) => (
  <div className={classnames(className, styles.container)}>
    <Grid container alignItems="center">
      <Grid item xs={12} md={6}>
        {withBadges && (
          <div className={styles.badges}>
            <Badge className={styles.badge} iconName="check" text="Engaging" />
            <Badge className={styles.badge} iconName="check" text="Unique" />
            <Badge className={styles.badge} iconName="check" text="Easy to Use" />
          </div>
        )}
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.desc}>{desc}</p>

        <div className={styles.btnContainer}>
          <CallToAction />
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <Image
          width="730"
          height="602"
          src={bigGooseParty}
          srcSet={`${bigGooseParty2x} 2x, ${bigGooseParty3x} 3x`}
          alt="big goose party"
        />
      </Grid>
    </Grid>
  </div>
);

TryItNowCTA.defaultProps = defaultProps;

export default TryItNowCTA;
