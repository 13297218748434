import React from 'react';
import classnames from 'classnames';

import styles from './Badge.module.scss';
import Icon, { IconNames } from './icons/Icon';

type Props = {
  className?: string;
  iconName?: IconNames;
  text: string;
  variant?: 'default' | 'plain';
};

const Badge: React.FunctionComponent<Props> = (props) => (
  <div className={classnames(styles.badge, props.className, styles[`badge-${props.variant}`])}>
    {props.iconName && <Icon className={styles.icon} name={props.iconName} height="1rem" />}
    <div>{props.text}</div>
  </div>
);

Badge.defaultProps = {
  variant: 'default',
};

export default Badge;
